import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SvgIcon from '@mui/icons-material/MailOutline';
import pepita from "./pepita.png";
import uniswap from "./uniswap.png";
import roadmap from "./roadmap.jpg";
import pepitaFull from "./pepitaFull.jpg";


function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        PEPITA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar style={{ backgroundColor: "#599940" }}>
          <div>
            <img
              style={{ borderRadius: "10px" }}
              src={pepita}
              alt="NFTs."
            />
          </div>
          <Typography variant="h6" color="white" noWrap style={{ marginLeft: "20px" }}>
            PEPITA
          </Typography>

          <button style={{ border: "1px solid white", backgroundColor: "#599940", borderRadius: "5px", padding: "5px", marginLeft: "auto" }}>
            <span style={{ color: "white" }}>BUY NOW</span>
          </button>

        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#065143"
              fontWeight="bold"
              fontSize="3rem"
              marginBottom="20px"
            >
              Where the f$ck is PEPE!
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <span style={{ color: '#599940', fontWeight: "bold" }}>PEPE</span> left his wife and seven little frogs with all their money.
              He is currently on the run to the top 10 Marketcap. Help <span style={{ color: '#599940', fontWeight: "bold" }}>PEPITA</span> get him.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              {/* <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button> */}
            </Stack>
          </Container>
        </Box>

        <Container
          sx={{ py: 8, background: "#599940" }}
          style={{ borderRadius: "20px", maxWidth: "95%", marginLeft: "auto", marginRight: "auto" }} maxWidth="md">
          <Typography
            component="h1"
            variant="h2"
            align="left"
            color="white"
          >
            <img
              style={{ borderRadius: "10px", width: "152px", marginLeft: "10px", float: "left", marginRight: "30px" }}
              src={pepitaFull}
              alt="NFTs."
            />
            About
          </Typography>
          <div style={{ color: "white", width: "100%", fontSize: "1.3rem", marginBottom: "20px" }}>
            $PEPITA is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.
          </div>
        </Container>

        <Container
          sx={{ py: 8, background: "white", marginTop: "20px", marginBottom: "20px", border: "3px solid #599940" }}
          style={{ borderRadius: "20px", maxWidth: "95%", marginLeft: "auto", marginRight: "auto" }} maxWidth="md">
          <Typography
            component="h1"
            variant="h2"
            align="left"
            color="#599940"
          >
            <img
              style={{ borderRadius: "10px", width: "152px", marginLeft: "10px", float: "right" }}
              src={roadmap}
              alt="NFTs."
            />
            <div style={{ display: "inline-block", height: "100%" }}>Roadmap</div>
          </Typography>
          <div style={{ color: "#599940", width: "100%", fontSize: "1.3rem", marginBottom: "20px", marginTop: "20px" }}>
            <div>Top 10 is our destination. Let's get there one frog leap after another.</div>
            <Grid container spacing={4}>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2" style={{ color: "#599940", fontWeight: "bold" }}>
                      Step 1
                    </Typography>
                    <Typography>
                      Get to the top 1000 by marketcap.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key={2} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2" style={{ color: "#599940", fontWeight: "bold" }}>
                      Step 2
                    </Typography>
                    <Typography>
                      Get to the top 100 by marketcap.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key={3} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2" style={{ color: "#599940", fontWeight: "bold" }}>
                      Step 3
                    </Typography>
                    <Typography>
                      Get to the top 10 by marketcap.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Container>
      </main>

      {/* Footer */}
      <Box sx={{ bgcolor: '#599940', p: 6, color: "white" }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          <a href='mailto:pepitathefrog@gmail.com?subject=Me&body=Hello!'>
            <SvgIcon style={{ height: "100%", fontSize: "1.5rem", color: "white" }}
              className="profile-icon"
              component={MailOutlineIcon}
            />
          </a>

          <img
            style={{ borderRadius: "10px", width: "30px", marginLeft: "10px" }}
            src={uniswap}
            alt="NFTs."
          />
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}